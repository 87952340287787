import { Fragment } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import "./DocumentationSection.css";
import PageDivider from "../page/PageDivider";

export default function DocumentationSection() {
  return (
    <Fragment>
      <section className="documentation-section">
        <div className="documentation-section-header">
          <h2>Using the Recipes API</h2>

          <div className="documentation-section-header-endpoint">
            <h2>POST https://api.mykitchenrecipes.app/v1/recipes</h2>
          </div>

          <p>
            The Recipes API provides a POST endpoint for you to post available
            ingredients by keys or queries.
          </p>
        </div>

        <div>
          <h3>Request parameters</h3>

          <div className="documentation-section-body">
            <ul className="documentation-section-body-parameters">
              <li>
                <div className="documentation-section-body-parameters-definition">
                  <h4>limit</h4>{" "}
                  <span>number</span>
                </div>

                <p>
                  Limit of recipes to query for, defaults to 3.
                </p>
              </li>
            </ul>

            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              customStyle={{
                flex: 2,
                height: "max-content",
                border: "0.2em solid",
                borderColor: "grey",
                borderRadius: "0.5em",
              }}
            >
              ?limit=3
            </SyntaxHighlighter>
          </div>
        </div>

        <div>
          <h3>Request body</h3>

          <div className="documentation-section-body">
            <ul className="documentation-section-body-parameters">
              <li>
                <div className="documentation-section-body-parameters-definition">
                  <h4>availableIngredients</h4>{" "}
                  <span>string[]</span>
                </div>

                <p>
                  List of ingredients as defined in the system that is available.
                </p>
              </li>

              <li>
                <div className="documentation-section-body-parameters-definition">
                  <h4>queryIngredients</h4>{" "}
                  <span>string[]</span>
                </div>

                <p>
                  List of queries to find ingredients defined in the system from and
                  use as available ingredients.
                </p>
              </li>

              <li>
                <div className="documentation-section-body-parameters-definition">
                  <h4>minimumMatchedIngredients</h4>{" "}
                  <span>number</span>
                </div>

                <p>
                  Number of minimum matched ingredients required for a recipe, defaults to 1.
                </p>
              </li>
            </ul>

            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              codeTagProps={{
                className: "documentation-section-code"
              }}
              customStyle={{
                flex: 2,
                height: "max-content",
                border: "0.2em solid",
                borderColor: "grey",
                borderRadius: "0.5em",
              }}
            >
              {JSON.stringify(
                {
                  "availableIngredients": [ "ris" ],
                  "queryIngredients": [ "Laxfilé" ],
                  "minimumMatchedIngredients": 2
                },
                undefined,
                2
              )}
            </SyntaxHighlighter>
          </div>
        </div>

        <div>
          <h3>Response body</h3>

          <SyntaxHighlighter
            language="json"
            style={vscDarkPlus}
            customStyle={{
              flex: 2,
              height: "max-content",
              border: "0.2em solid",
              borderColor: "grey",
              borderRadius: "0.5em",
            }}
          >
            {JSON.stringify(
              {
                "data": {
                    "recipes": [
                        {
                            "id": "fe771281-dbbd-40f3-8da1-1d6c98835446",
                            "title": "Bowl med misobakad lax, fänkålsslaw och svart ris",
                            "description": "En skål fylld av umami där en bas av svart ris får sällskap av misobakad lax, krämig fänkålsslaw och inlagd gurka. Äts tillsammans med en sötsyrlig citrondressing.",
                            "image": "https://assets.icanet.se/t_ICAseAbsoluteUrl/imagevaultfiles/id_248272/cf_259/bowl_med_misobakad_lax__fankalsslaw_och_svart_ris.jpg",
                            "url": "https://www.ica.se/recept/bowl-med-misobakad-lax-fankalsslaw-och-svart-ris-729766/",
                            "source": "ica",
                            "rank": 2
                        },
                        {
                            "id": "fa3a4bbb-88f4-4ca3-84aa-a6c2757295e5",
                            "title": "Fiskkakor med krassesås",
                            "description": "Fiskkakor är en spännande fiskrätt som serveras tillsammans med smarrig krassesås. Löken krossas och blandas med ströbrödet där krassen klipps ned mot slutet. Därefter paneras den mixade laxen för att sedan stekas frasig i olja. Avnjut med nykokt ris, blomkål och den syrliga såsen.",
                            "image": "https://assets.icanet.se/t_ICAseAbsoluteUrl/imagevaultfiles/id_120222/cf_259/fiskkakor_med_krassesas.jpg",
                            "url": "https://www.ica.se/recept/fiskkakor-med-krassesas-719192/",
                            "source": "ica",
                            "rank": 2
                        },
                        {
                            "id": "f069558d-336b-4004-a15b-0e28e9735114",
                            "title": "Sesambakad lax med ris",
                            "description": "Sesambakad lax med ris är en lättlagad fiskrätt som du slänger ihop på cirka 30 minuter. Laxfilén kryddas med salt och peppar där sesamfröna sedan strösslas över och tillagas i ugnen. Det ger dig tid att förbereda tillbehören fräst lök och pak choi som får smak av teriyakisås. Avnjut med ris och limeklyftor.",
                            "image": "https://assets.icanet.se/t_ICAseAbsoluteUrl/imagevaultfiles/id_122772/cf_259/sesambakad_lax_med_ris.jpg",
                            "url": "https://www.ica.se/recept/sesambakad-lax-med-ris-719379/",
                            "source": "ica",
                            "rank": 2
                        }
                    ]
                }
            },
              undefined,
              2
            )}
          </SyntaxHighlighter>
        </div>
        
        <PageDivider/>
        
        <div className="documentation-section-header">
          <h2>Using the Ingredients API</h2>

          <div className="documentation-section-header-endpoint">
            <h2>GET https://api.mykitchenrecipes.app/v1/ingredients</h2>
          </div>

          <p>
          The Ingredients API provides a GET endpoint for you to query for
          available ingredients from a string.
          </p>
        </div>

        <div>
          <h3>Request parameters</h3>

          <div className="documentation-section-body">
            <ul className="documentation-section-body-parameters">
              <li>
                <div className="documentation-section-body-parameters-definition">
                  <h4>query</h4>{" "}
                  <span>string</span>
                </div>

                <p>
                  String to find ingredients for.
                </p>
              </li>
            </ul>

            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              customStyle={{
                flex: 2,
                height: "max-content",
                border: "0.2em solid",
                borderColor: "grey",
                borderRadius: "0.5em",
              }}
            >
              ?query=mjölk
            </SyntaxHighlighter>
          </div>
        </div>

        <div>
          <h3>Response body</h3>

          <SyntaxHighlighter
            language="json"
            style={vscDarkPlus}
            customStyle={{
              flex: 2,
              height: "max-content",
              border: "0.2em solid",
              borderColor: "grey",
              borderRadius: "0.5em",
            }}
          >
            {JSON.stringify(
              {
                "data": {
                  "ingredients": [
                    "mjölk",
                    "kondenserad mjölk",
                    "karamelliserad mjölk"
                  ]
                }
              },
              undefined,
              2
            )}
          </SyntaxHighlighter>
        </div>
      </section>
    </Fragment>
  );
}
