import { Fragment } from "react";
import LandingView from "../views/LandingView";
import appStoreBadge from "../images/app-store-badge.png";
import googlePlayBadge from "../images/google-play-badge.png";
import { BackgroundShape1, BackgroundShape2, BackgroundShape3 } from "../components/shapes/BackgroundShapes";
import Footer from "../components/page/Footer";
import screenshotFrameIndex from "../images/screenshot-frame-index.png";
import screenshotFrameRecipes from "../images/screenshot-frame-recipes.png";

export default function Index() {
  return (
    <Fragment>
      <LandingView images={(
        <Fragment>
          <BackgroundShape1 className="landing-view-vector-shape" style={{
            top: "0%"
          }}/>

          <div style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            position: "relative",
            marginTop: "-5%"
          }}>
            <img alt="Index screenshot" src={screenshotFrameIndex} className="landing-view-screenshot"/>

            <img alt="Recipes screenshot" src={screenshotFrameRecipes} className="landing-view-screenshot"/>
          </div>

          <img className="landing-view-vector-image" alt="" src="/images/undraw_barista_qd50.svg" style={{
            position: "absolute",
            
            width: "15vw",

            right: 0,
            bottom: "-23%",

            zIndex: 3
          }}/>
        </Fragment>
      )}>
        <h2>
          Slipp slösa mat och pengar med matspararen!
        </h2>

        <p>
          Få notiser några dagar innan dina produkter går ut och hitta recept som passar det du har i köket.
        </p>

        <div className="landing-view-badges">
          <a href="https://apps.apple.com/app/id6475767599" target="_blank" rel="noreferrer">
            <img alt="App Store badge" src={appStoreBadge}/>
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.norasoderlund.mykitchenrecipes" target="_blank" rel="noreferrer">
            <img alt="Google Play badge" src={googlePlayBadge}/>
          </a>

          {/*<a href={"https://app.mykitchenrecipes.app"} target="_blank" rel="noreferrer" style={{
            height: "100%"
          }}>
            <img alt="Web App badge" src={webAppBadge} style={{
              height: "100%",
              cursor: "not-allowed"
            }}/>
          </a>*/}
        </div>
      </LandingView>

      <LandingView images={(
        <Fragment>
          <BackgroundShape2 className="landing-view-vector-shape"/>

          <img className="landing-view-vector-image" alt="" src="/images/undraw_share_opinion_re_4qk7.svg"/>
        </Fragment>
      )}>
        <h2>
          Påminnelser för utgående varor
        </h2>

        <p>
          Få påminnelser om produkter i ditt kök innan de går ut. Slipp slösa mat och pengar med matspararen!
        </p>
      </LandingView>

      <LandingView images={(
        <Fragment>
          <BackgroundShape3 className="landing-view-vector-shape"/>

          <img className="landing-view-vector-image" alt="" src="/images/undraw_online_groceries_a02y.svg"/>
        </Fragment>
      )}>
        <h2>
          Recept baserade på dina ingredienser
        </h2>

        <p>
        Med över 4,000 basingredienser erbjuder vi länkar till hela 19,000 olika recept som matchar just det du har hemma. Mångfald i varje tugga!
        </p>
      </LandingView>

      <Footer/>
    </Fragment>
  );
}
