import PageFooter from "../components/page/Footer";
import PageHeader from "../components/page/PageHeader";
import PrivacyPolicy from "../components/sections/PrivacyPolicy";
import { Fragment } from "react";

export default function PrivacyPolicyPage() {
  return (
    <Fragment>
      <PageHeader/>

      <PrivacyPolicy/>

      <PageFooter/>
    </Fragment>
  );
}
