import { ReactNode } from "react";
import "./LandingView.css";

export type LandingViewProps = {
  images: ReactNode;
  children: ReactNode;
}

export default function LandingView({ images, children }: LandingViewProps) {
  return (
    <div className="landing-view">
      <div className="landing-view-container container">
        <div className="landing-view-intro">
          {children}
        </div>

        <div className="landing-view-vector">
          {images}
        </div>
      </div>
    </div>
  );
}
