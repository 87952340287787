import { ReactNode } from "react";
import "./Footer.css";

type PageFooterProps = {
  disclaimers?: ReactNode;
}

export default function Footer({ disclaimers }: PageFooterProps) {
  return (
    <footer className="footer">
      <div>
        <a href="https://studionora.se">Studio Nora</a>

        <p style={{ fontSize: "0.8em" }}>Logo image made by <a href="https://www.freepik.com/free-vector/flat-pantry-collection-illustrated_10503175.htm" target="_blank" rel="noreferrer">Freepik</a>.</p>
      </div>

      <div className="footer-disclaimers">
        <p>Google Play and the Google Play logo are trademarks of Google LLC.</p>
        <p>App Store and the Apple logo are trademarks of Apple Inc.</p>
      </div>
    </footer>
  );
}