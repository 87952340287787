import { Fragment, KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./WordInput.css";

type WordInputProps = {
  onChange: (text: string[]) => void;
}

export default function WordInput({ onChange }: WordInputProps) {
  const [ words, setWords ] = useState<string[]>([]);

  useEffect(() => {
    if(words.length) {
      onChange(words);
    }
  }, [ words, onChange ])

  const handleOnKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if(event.nativeEvent.code === "Space" || event.nativeEvent.code === "Enter") {
      const target = (event.target as HTMLInputElement);

      const [ firstWord, secondWord ] = target.value.split(' ')

      if(firstWord.length) {
        setWords([ ...words, firstWord ]);
      }

      target.value = secondWord ?? "";
    }
  }, [ words ]);

  const handleOnClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    const target = (event.target as HTMLAnchorElement);

    setWords([ ...words, target.innerText ]);
  }, [ words ]);

  return (
    <Fragment>
      <div className="word-input">
        {words.map((word, index) => (
          <div key={word} className="word-input-item">
            {word}

            <FontAwesomeIcon className="word-input-delete" icon={faTimes} onClick={() => {
              const newWords = [...words];

              newWords.splice(index, 1);

              setWords(newWords);
            }}/>
          </div>
        ))}

        <input className="word-input-field" type="text" placeholder="Enter some ingredients" onKeyUp={handleOnKeyUp}/>
      </div>

      <p>Try ingredients such as <a href="#ingredients" onClick={handleOnClick}>vetemjöl</a>, <a href="#ingredients" onClick={handleOnClick}>ost</a>, <a href="#ingredients" onClick={handleOnClick}>kyckling</a>, <a href="#ingredients" onClick={handleOnClick}>köttfärs</a>, <a href="#ingredients" onClick={handleOnClick}>taco</a></p>
    </Fragment>
  )
}