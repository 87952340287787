import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeader from "../../components/page/PageHeader";
import { BackgroundShape4 } from "../../components/shapes/BackgroundShapes";
import { faImage, faKitchenSet } from "@fortawesome/free-solid-svg-icons";
import PageFooter from "../../components/page/Footer";
import WordInput from "../../components/form/WordInput";
import useDynamicChange from "../../hooks/useDynamicChange";
import { Fragment, useState } from "react";
import Recipe from "../../models/Recipe";
import DocumentationSection from "../../components/sections/DocumentationSection";

export default function ApiPage() {
  const [ recipes, setRecipes ] = useState<Recipe[] | null>(null);
  
  return (
    <Fragment>
      <PageHeader/>

      <section id="ingredients" style={{
        padding: "15vh 20% 0",

        minHeight: "40vh",

        display: "flex",
        flexDirection: "row",
        gap: "1em"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          padding: "5vw 0"
        }}>
          <h2 style={{ margin: 0 }}>We also provide an API for fetching recipes based on ingredients</h2>
          <p style={{ margin: 0 }}>Our API is available without any token needed, however, it is rate limited unless you sign up.</p>
        
          <WordInput onChange={useDynamicChange(async (words) => {
            if(!words.length) {
              setRecipes(null);

              return;
            }

            const response = await fetch("https://api.mykitchenrecipes.app/v1/recipes", {
              method: "POST",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-Purpose": "Official demo"
              },
              body: JSON.stringify({
                queryIngredients: words
              })
            });

            if(!response.ok) {
              return;
            }

            type Response = {
              data: {
                recipes: Recipe[];
              }
            };

            const result: Response = await response.json();

            setRecipes(result.data.recipes);
          })}/>

          <div style={{ marginTop: "3em" }}>
            {(recipes !== null) && (
              (recipes.length)?(
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em"
                }}>
                  {recipes.map((recipe) => (
                    <div key={recipe.id} style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1em"
                    }}>
                      <div style={{
                        width: "8em",
                        height: "8em",

                        position: "relative",
                        aspectRatio: 1
                      }}>
                        <div style={{
                          position: "absolute",
                          
                          left: 0,
                          top: 0,

                          width: "100%",
                          height: "100%",
                          
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          zIndex: 0
                        }}>
                          <FontAwesomeIcon icon={faImage} color={"grey"} fontSize={"2em"}/>
                        </div>

                        {(recipe.image) && (
                          <div style={{
                            background: `url(${recipe.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            flex: 1,

                            width: "100%",
                            height: "100%",

                            aspectRatio: 1,
                            zIndex: 1,
                            position: "relative"
                          }}/>
                        )}
                      </div>

                      <div style={{
                        flex: 1,

                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5em"
                      }}>
                        <h3 style={{ margin: 0 }}>{recipe.title}</h3>
                        <p style={{ margin: 0 }}>{recipe.description}</p>
                        <p style={{ margin: 0 }}><a href={recipe.url} target="_blank" rel="noreferrer">View recipe in {(recipe.source === "ica")?("Ica"):(recipe.source)}</a></p>
                      </div>
                    </div>
                  ))}
                </div>
              ):(
                <h2>No recipes were found, try with less or different ingredients!</h2>
              )
            )}
          </div>
        </div>

        <div style={{
          width: "20vw"
        }}>
          <div style={{
            width: "20vw",
            aspectRatio: 1,

            marginLeft: "auto",

            position: "relative",
  
            display: "flex",
  
            justifyContent: "center",
            alignItems: "center"
          }}>
            <BackgroundShape4 style={{
              position: "absolute",
              
              left: 0,
              top: 0,

              width: "100%",
              height: "100%"
            }}/>

            <FontAwesomeIcon icon={faKitchenSet} color={"red"} style={{
              zIndex: 1,

              height: "30%"
            }}/>
          </div>
        </div>
      </section>
      
      <DocumentationSection/>

      <section style={{
        padding: "0 20% 0",
        gap: "1em",
        display: "flex",
        flexDirection: "column"
      }}>
        <h2>Upgrading to a paid consumer</h2>

        <p>Please send an email to <a href="mailto:contact@mykitchenrecipes.app">contact@mykitchenrecipes.app</a> to become a paid consumer. All billing is made via <a href="https://stripe.com/" target="_blank" rel="noreferrer">Stripe, Inc</a>.</p>
      </section>

      <PageFooter/>
    </Fragment>
  );
}
