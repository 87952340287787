import { Fragment } from "react";

import "./DocumentationSection.css";

export default function TermsOfUseSection() {
  return (
    <Fragment>
      <section className="documentation-section">
        <div className="documentation-section-header">
          <h2>Terms Of Use</h2>

          <p>
            By accessing or using our APIs, you are agreeing to the terms below. You agree to comply with the Terms and that the Terms control your relationship with us.
          </p>

          <p>
            You are to abide by all applicable laws and regulations. You may not use the APIs and may not accept the Terms if you are not of legal age to form a binding contract.
          
            You are to use the API only for its intended purpose as described in our documentation.

            You are to avoid misuse, abuse, or any activity that may harm the API or our services.

            We are not liable for any damages or losses resulting from our API usage.
          </p>
        </div>
      </section>
    </Fragment>
  );
}
