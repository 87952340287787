import "./PageHeader.css";

export default function PageHeader() {
  return (
    <header className="page-header">
      <div>
      </div>

      <div className="page-header-links">
        <a href="/">
          Hem
        </a>

        <a href="/recipes-api">
          Utvecklartjänst
        </a>
      </div>
    </header>
  );
}