import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Index';
import ApiPage from './pages/api/ApiPage';

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import ApiTermsOfUsePage from './pages/api/ApiTermsOfUsePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Index/>}/>
        <Route path="/" element={<Index/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
        <Route path="/api" element={<ApiPage/>}/>
        <Route path="/recipes-api" element={<ApiPage/>}/>
        <Route path="/recipes-api/terms-of-use" element={<ApiTermsOfUsePage/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
