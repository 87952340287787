import PageHeader from "../../components/page/PageHeader";
import PageFooter from "../../components/page/Footer";
import TermsOfUseSection from "../../components/sections/TermsOfUseSection";
import { Fragment } from "react";

export default function ApiTermsOfUsePage() {
  return (
    <Fragment>
      <PageHeader/>

      <TermsOfUseSection/>

      <PageFooter/>
    </Fragment>
  );
}
